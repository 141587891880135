import React, { useState, useEffect, useRef } from "react"
import classnames from "classnames"
import "./BookingDropdown.css"

const BookingDropdown = () => {
  const ref = useRef(null)
  const [open, setOpen] = useState(false)
  const handleToggle = () => setOpen(!open)

  useEffect(() => {
    const handleClickOutside = ({ target }) => {
      if (!ref.current.contains(target)) {
        setOpen(false)
      }
    }

    if (open) {
      document.addEventListener("click", handleClickOutside)
    } else {
      document.removeEventListener("click", handleClickOutside)
    }

    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [open])

  const items = [
    {
      label: "Anne Ravn",
      url: "https://anneravn.klikbook.dk",
    },
    {
      label: "Marianna",
      url: "https://copenhair.klikbook.dk",
    },
    {
      label: "Sharon Hatting",
      url: "https://hairbysharonhatting.com/services",
    },
  ]

  return (
    <div
      className={classnames("booking-dropdown", { "is-open": open })}
      ref={ref}
    >
      <button
        className="booking-dropdown__toggle"
        onClick={handleToggle}
        id="book-tid"
      >
        Online Booking
        <svg
          className="booking-dropdown__toggle__icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 32"
        >
          <path d="M14.7 9.4L4.9 19c-.8.8-.8 2 0 2.8s2 .8 2.8 0l8.4-8.2 8.2 8.2c.8.8 2 .8 2.8 0s.8-2 0-2.8l-9.6-9.6c-.9-.8-2.1-.8-2.8 0z" />
        </svg>
      </button>
      <ul className="booking-dropdown__overlay">
        {items.map(item => (
          <li key={item.label} className="booking-dropdown__overlay__item">
            <a
              target="_blank"
              rel="noreferrer"
              href={item.url}
              onClick={handleToggle}
            >
              {item.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BookingDropdown
