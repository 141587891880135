import React, { useState, useEffect } from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import logo from "../components/logo.svg"
import BookingDropdown from "../components/BookingDropdown"

import SEO from "../components/seo"

const calendarIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    xmlSpace="preserve"
  >
    <path d="M57.3 137.5h20.8v20.8H57.3zM57.3 170.9h20.8v20.8H57.3zM253.5 189.7h-18.9v-18.9c0-5.9-4.8-10.7-10.7-10.7-5.9 0-10.7 4.8-10.7 10.7v18.9h-18.9c-5.9 0-10.7 4.8-10.7 10.7 0 5.9 4.8 10.7 10.7 10.7h18.9V230c0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7v-18.9h18.9c5.9 0 10.7-4.8 10.7-10.7 0-5.9-4.8-10.7-10.7-10.7z" />
    <path d="M258.5 143.6v-91c0-5.9-4.8-10.7-10.7-10.7h-30.3v-7.1c0-5.9-4.8-10.7-10.7-10.7-5.9 0-10.7 4.8-10.7 10.7v7.1H80.9v-7.1c0-5.9-4.8-10.7-10.7-10.7-5.9 0-10.7 4.8-10.7 10.7v7.1H27.9c-5.9 0-10.7 4.8-10.7 10.7v174.9c0 5.9 4.8 10.7 10.7 10.7h141.2v-.2c12 17.4 32.1 28.8 54.8 28.8 36.7 0 66.5-29.8 66.5-66.5 0-23.9-12.8-45-31.9-56.7zM38.6 63.3h20.8v7.1c0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7v-7.1H196v7.1c0 5.9 4.8 10.7 10.7 10.7 5.9 0 10.7-4.8 10.7-10.7v-7.1H237v41.8H38.6V63.3zm0 153.4v-101H237v19.5c-4.2-.9-8.6-1.3-13.1-1.3-14.1 0-27.2 4.4-38 12v-8.4h-20.8v20.8h7.5c-9.4 11.5-15.1 26.2-15.1 42.1 0 5.6.7 11.1 2 16.3H38.6zm185.3 39.5c-30.7 0-55.8-25-55.8-55.8 0-30.8 25-55.8 55.8-55.8 30.8 0 55.8 25 55.8 55.8 0 30.8-25 55.8-55.8 55.8z" />
    <path d="M93.2 170.9H114v20.8H93.2zM129.2 137.5v20.8h20.7v-20.8h-20.7zM129.2 170.9v20.7h20.7v-20.7h-20.7zM93.2 137.5H114v20.8H93.2z" />
  </svg>
)
const mailIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    xmlSpace="preserve"
  >
    <path d="M282.2 61.2c-.2-.1-.3-.3-.5-.4-5-3.9-11.1-6-17.4-6.1H35.7c-6.3 0-12.4 2.2-17.4 6.1-.2.1-.3.3-.5.4-6.7 5.4-10.6 13.5-10.7 22.1v133.3c0 15.8 12.8 28.6 28.6 28.6h228.6c15.8 0 28.6-12.8 28.6-28.6V83.3c-.1-8.6-4-16.7-10.7-22.1zM251 73.8l-101 64.9L49 73.8h202zm22.8 142.9c0 5.3-4.3 9.5-9.5 9.5H35.7c-5.3 0-9.5-4.3-9.5-9.5V83.3c.1-.5.1-.9.3-1.4l118.4 76c3.1 2 7.2 2 10.3 0l118.4-76c.1.4.2.9.3 1.4v133.4z" />
  </svg>
)
const instaIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 300 300"
    xmlSpace="preserve"
  >
    <path d="M237.7 18.4H62.3c-24.2 0-43.9 19.6-43.9 43.9v175.4c0 24.2 19.6 43.9 43.9 43.9h175.4c24.2 0 43.9-19.6 43.9-43.9V62.3c0-24.2-19.7-43.9-43.9-43.9zm21.9 219.3c0 12.1-9.8 21.9-21.9 21.9H62.3c-12.1 0-21.9-9.8-21.9-21.9V62.3c0-12.1 9.8-21.9 21.9-21.9h175.4c12.1 0 21.9 9.8 21.9 21.9v175.4z" />
    <path d="M150 84.2c-36.3 0-65.8 29.5-65.8 65.8s29.5 65.8 65.8 65.8 65.8-29.5 65.8-65.8-29.5-65.8-65.8-65.8zm0 109.7c-24.2 0-43.9-19.6-43.9-43.9s19.6-43.9 43.9-43.9c24.2 0 43.9 19.6 43.9 43.9s-19.7 43.9-43.9 43.9z" />
    <circle cx="221.3" cy="78.7" r="16.4" />
  </svg>
)
const caretUpIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path d="M14.7 9.4L4.9 19c-.8.8-.8 2 0 2.8s2 .8 2.8 0l8.4-8.2 8.2 8.2c.8.8 2 .8 2.8 0s.8-2 0-2.8l-9.6-9.6c-.9-.8-2.1-.8-2.8 0z" />
  </svg>
)

const NavItem = ({ href, children, fadeIn }) => (
  <li className={fadeIn ? "fade" : ""}>
    <a href={href}>{children}</a>
  </li>
)

const IndexPage = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false)
  const [navOpen, setNavOpen] = useState(false)
  const toggleMobileNav = () => setNavOpen(prevState => !prevState)

  const handleScrollTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    })
  }

  useEffect(() => {
    const OFFSET = 100

    const scrollFunction = () => {
      if (!showScrollTopButton && window.scrollY > OFFSET) {
        setShowScrollTopButton(true)
      } else if (showScrollTopButton && window.scrollY < OFFSET) {
        setShowScrollTopButton(false)
      }
    }

    document.addEventListener("scroll", scrollFunction)

    return () => {
      document.removeEventListener("scroll", scrollFunction)
    }
  }, [showScrollTopButton])

  return (
    <>
      <SEO title="Velkommen" />
      {showScrollTopButton && (
        <button onClick={handleScrollTop} id="toTop" title="Gå til toppen">
          {caretUpIcon}
        </button>
      )}
      <nav>
        <a href=".">
          <img src={logo} alt="RAVNKØBENHAVN LOGO" className="logo" />
        </a>
        <button className="hamburger" onClick={toggleMobileNav}>
          <div className="line" />
          <div className="line" />
          <div className="line" />
        </button>
        <ul
          className={classnames("nav-links", { ease: !navOpen, open: navOpen })}
        >
          <NavItem href="#boss-ladies" fadeIn={navOpen}>
            Frisører
          </NavItem>
          <NavItem href="#salonen" fadeIn={navOpen}>
            Salonen
          </NavItem>
          <NavItem href="#info" fadeIn={navOpen}>
            Info &amp; Kontakt
          </NavItem>
        </ul>
      </nav>

      <section className="landing">
        <h2>Velkommen til RavnKøbenhavn</h2>
        <p>
          Du er i fokus og det er vigtigt for os, at du får en lækker hverdags
          wellness-oplevelse
        </p>
        <BookingDropdown />
      </section>
      <section className="boss-ladies" id="boss-ladies">
        <div>
          <h2>Boss Ladies</h2>
          <p>
            Vi er et <i>frisørkollektiv</i>, det vil sige at vi er alle
            selvstændige frisører, men under samme tag.
          </p>
          <p id="new-boss-ladies">
            <strong>Kunne du tænke dig at være del af teamet?</strong> Kontakt
            Anne på{" "}
            <a href="mailto:anne@ravnkoebenhavn.dk" rel="noopener">
              anne@ravnkoebenhavn.dk
            </a>
          </p>
        </div>
        <div className="flex-container">
          <div className="hair-dresser">
            <img src="./img/indehaver.jpg" alt="Anne" />
            <p className="function">Frisør</p>
            <p className="name">Anne</p>
            <p className="socials">
              <a
                href="https://instagram.com/anneravnkoebenhavn"
                target="_blank"
              >
                {instaIcon}
                <span className="label"> Følg på Instagram</span>
              </a>
              <a href="http://anneravn.klikbook.dk/" target="_blank">
                {calendarIcon}
                <span className="label">
                  {" "}
                  Book tid
                  <br />
                </span>
              </a>
              <a href="mailto:anne@ravnkoebenhavn.dk">
                {mailIcon}
                <span className="label">Send e-mail</span>
              </a>
            </p>
          </div>
          <div className="hair-dresser">
            <img src="./img/marianna.jpg" alt="Marianna" width={300} />
            <p className="function">Frisør</p>
            <p className="name">Marianna</p>
            <p className="socials">
              <a href="https://instagram.com/copenhair" target="_blank">
                {instaIcon}
                <span className="label"> Følg på Instagram</span>
              </a>
              <a href="https://copenhair.klikbook.dk" target="_blank">
                {calendarIcon}
                <span className="label"> Book tid</span>
              </a>
              <a href="mailto:marianna.bartalis@gmail.com">
                {mailIcon}
                <span className="label"> Send e-mail</span>
              </a>
            </p>
          </div>
          <div className="hair-dresser">
            <img
              src="./img/sharon.jpg"
              alt="Hair by Sharon Hatting"
              width={300}
            />
            <p className="function">Frisør</p>
            <p className="name">Sharon Hatting</p>
            <p className="socials">
              <a href="https://instagram.com/sharonhatting" target="_blank">
                {instaIcon}
                <span className="label"> Følg på Instagram</span>
              </a>
              <a
                href="https://www.hairbysharonhatting.com/services"
                target="_blank"
              >
                {calendarIcon}
                <span className="label"> Book tid</span>
              </a>
              <a href="mailto:sharonhatting123@gmail.com">
                {mailIcon}
                <span className="label"> Send e-mail</span>
              </a>
            </p>
          </div>
        </div>
      </section>
      <section id="salonen" className="infos">
        <h2>Salonen</h2>
        <h3>I vores salon er alle velkomne!</h3>
        <p />
        <p>
          Vi er en <strong>kønsneutral</strong> salon, det vil sige at der ikke
          er forskellige priser ligemeget hvilket køn du er.
        </p>
        <p>
          Du er i fokus og det er vigtigt for os, at du får en lækker hverdags
          wellness-oplevelse.
        </p>
        <p>
          Derfor kan du til din aftale få serveret lækre drikkevarer, som
          friskbrygget stempelkaffe, øl eller hyldeblomstsaft - Drikkevarerne
          varierer efter sæson.
        </p>
        <p>
          Produkterne vi bruger er fra <strong>Kevin Murphy</strong>. Disse er
          ikke testet på dyr, fri for sulphater og emballagen er lavet af
          opsamlet plastik fra havet.
        </p>
        <p>
          Vi har gjort os mange tanker om hvordan vi kan minimere vores
          belastning på kloden, indenfor vores fag. Vi har taget disse tiltag:
        </p>
        <p>
          Strømmen vi benytter os af er produceret af vind-energi, og for at
          mindske vores vandforbrug med op til 60%. har vi udskiftet
          brusehovedet på kundevasken.
        </p>
      </section>
      <section id="info" className="infos">
        <h2>Info &amp; Kontakt</h2>
        <p>Åbningstider:</p>
        <p>
          Efter aftale med din frisør - se ledige tider på den pågældende
          frisørs booking side
        </p>
        <p>Adressen er:</p>
        <p>
          RavnKøbenhavn
          <br />
          Sølvgade 89
          <br />
          1307 København K
        </p>
        <p>
          Følg RavnKøbenhavn på{" "}
          <a href="https://instagram.com/anneravnkoebenhavn" target="_blank">
            Instagram
          </a>{" "}
          og
          <a href="https://fb.me/ravnkoebenhavn" target="_blank">
            Facebook
          </a>
        </p>
      </section>
      <footer>
        © RavnKøbenhavn 2020 <br />
        Vi benytter ikke cookies og gemmer ingen data om dig!
      </footer>
    </>
  )
}

export default IndexPage
